import React from 'react'
import { As } from '@hub/design-system-base'
import { RetailerDetails, StoreGrid } from './index'
import { SGPageTemplateContentEntry } from '@scentregroup/shared/types/page-templates'

type Props = {
  title?: string
  entries: (SGPageTemplateContentEntry & { viewItem: () => void; as?: As })[]
  shortDescription?: string
  as?: As
  clickEvent: (index: number) => void
  enableH1Heading?: boolean
}

function SimpleStoreGrid({
  title,
  shortDescription,
  entries,
  as,
  clickEvent,
  enableH1Heading: enableH1Heading,
}: Props): JSX.Element {
  const retailers: RetailerDetails[] = entries.map((entry, index) => ({
    title: entry.title,
    shortDescription: entry.shortDescription || undefined,
    logoUrl: entry.image.url,
    url: entry.callToAction.url,
    as: entry.as,
    index,
    viewItem: entry.viewItem,
  }))

  return (
    <StoreGrid
      retailers={retailers}
      title={title}
      subtitle={shortDescription}
      as={as}
      clickEvent={clickEvent}
      enableH1Heading={enableH1Heading}
    />
  )
}

export default SimpleStoreGrid
