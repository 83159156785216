import React from 'react'
import { Text } from '@hub/text'
import { H3 } from '@hub/heading'
import { CoreContainer } from '@hub/core-container'
import { Link } from '@hub/link'
import { As } from '@hub/design-system-base'
import { Box } from '@hub/box'
import {
  CloudinaryImage,
  IMAGE_SIZES_GRID_PRIMARY,
  IMAGE_SIZES_GRID_SECONDARY,
} from '../cloudinary-image'
import trackClickEvents, { categories } from '../analytics/trackEvents'
import { TrackBox, TrackLinkBox } from '../../analytics-observer'

const MAX_PRIMARY_RETAILERS = 6

export interface RetailerDetails {
  title: string
  logoUrl: string
  alternativeLogoUrls?: {
    coloured: string
    transparent: string
  }
  shortDescription?: string
  url: string
  as?: As
  index?: number
}

interface StoreGridProps {
  /** Title for the store grid, defaulted to 'Shop the stores you love' */
  title?: string
  /** Subtitle for the store grid, defaulted to 'Delivered in 2 hours or click & collect in 30 minutes' */
  subtitle?: string

  /** Provide a list of all retailers to display and it will be split between major and minor */
  retailers?: RetailerDetails[]
  /** Information on the smaller store logos. These will always be two rows */
  secondaryRetailers?: RetailerDetails[]
  /** Information on the larger store logos - there should be exactly six of these provided - other numbers may cause problems */
  primaryRetailers?: RetailerDetails[]
  as?: As
  clickEvent?: (index: number) => void
  enableH1Heading?: boolean
}

export const StoreGrid: React.FC<React.PropsWithChildren<StoreGridProps>> = ({
  title,
  subtitle,
  retailers = [],
  secondaryRetailers = retailers?.slice(MAX_PRIMARY_RETAILERS),
  primaryRetailers = retailers?.slice(0, MAX_PRIMARY_RETAILERS),
  as,
  clickEvent,
  enableH1Heading: enableH1Heading,
}) => {
  // we dont know how many grid cols up front, this is a good way to handle it dynamically
  const gridCols = `grid-cols-[repeat(auto-fit,minmax(min(theme(spacing.size-14),100%),1fr))]`
  const mdGridCols = `md:grid-cols-[repeat(auto-fit,minmax(min(theme(spacing.size-10),100%),1fr))]`

  return (
    <CoreContainer as={as}>
      <div className="pb-spacing-sm">
        {title && (
          <H3 as={enableH1Heading ? 'h1' : 'h3'} align="center">
            {title}
          </H3>
        )}
        {subtitle && (
          <Text align="center" color={'textSecondary'}>
            {subtitle}
          </Text>
        )}
      </div>
      <div className="space-y-spacing-lg overflow-hidden">
        <div
          className={`grid gap-spacing-sm sm:gap-spacing-md ${gridCols} ${mdGridCols}`}
        >
          {primaryRetailers.map(({ logoUrl, title, url, as, index }) => (
            // SGA11y: The role attribute is used to indicate the purpose of the element within the page.
            // The listitem role is used to indicate that the element is a list item.
            <Box as={as} key={index} className="border border-border-tertiary">
              <TrackBox key={url} role="listitem">
                <Link
                  href={url}
                  onClick={() => {
                    if (clickEvent) {
                      clickEvent(index ?? 0)
                    }
                    trackClickEvents.clicked(
                      categories.STORE_GRID,
                      `Primary Retailer: ${title}`
                    )
                  }}
                >
                  <CloudinaryImage
                    ratio="1"
                    imageSetOrImage={{
                      url: logoUrl,
                      alt: title,
                      type: 'SQUARE',
                    }}
                    minWidth="size-sm"
                    resizeMode="pad"
                    sizes={IMAGE_SIZES_GRID_PRIMARY(primaryRetailers.length)}
                  />
                </Link>
              </TrackBox>
            </Box>
          ))}
        </div>

        {secondaryRetailers.length > 0 && (
          <div className="flex flex-wrap justify-center gap-spacing-lg">
            {secondaryRetailers.map(
              ({ alternativeLogoUrls, logoUrl, title, url, as, index }) => (
                <Box as={as} className="m-2 basis-size-9">
                  <div
                    key={url}
                    className="flex items-center border-border-tertiary"
                    role="listitem"
                  >
                    <TrackLinkBox
                      onClick={() => {
                        if (clickEvent) {
                          clickEvent(index ?? 0)
                        }
                        trackClickEvents.clicked(
                          categories.STORE_GRID,
                          `Secondary Retailer: ${title}`
                        )
                      }}
                    >
                      <Link href={url}>
                        <div className="opacity-70 grayscale">
                          <CloudinaryImage
                            ratio="1"
                            imageSetOrImage={{
                              url:
                                alternativeLogoUrls?.coloured ??
                                alternativeLogoUrls?.transparent ??
                                logoUrl,
                              alt: title,
                              type: 'SQUARE',
                            }}
                            sizes={IMAGE_SIZES_GRID_SECONDARY}
                            minWidth={'size-9'}
                          />
                        </div>
                      </Link>
                    </TrackLinkBox>
                  </div>
                </Box>
              )
            )}
          </div>
        )}
      </div>
    </CoreContainer>
  )
}
