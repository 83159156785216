import { NodeData } from '@contentful/rich-text-types'

export type RichTextEmbeddedEntryData = {
  [key in keyof RichTextEmbeddedEntryTargetByContentType]: {
    contentType: key
    target: RichTextEmbeddedEntryTargetByContentType[key]
  }
}[keyof RichTextEmbeddedEntryTargetByContentType]

type RichTextEmbeddedEntryTargetByContentType = {
  richTextCallToAction: RichTextEmbeddedEntryTargetRichTextCallToAction
  richTextVideo: RichTextEmbeddedEntryTargetRichTextVideo
}

type RichTextEmbeddedEntryTargetEntry<
  ContentType extends string,
  Fields extends Record<string, unknown> = Record<string, unknown>,
> = {
  sys: {
    id: string
    contentType: {
      sys: {
        id: ContentType
      }
    }
  }
  fields: Fields
}

export type RichTextEmbeddedEntryTargetRichTextCallToAction =
  RichTextEmbeddedEntryTargetEntry<
    'richTextCallToAction',
    {
      title: string
      address: string
      variant: string
    }
  >

export type RichTextEmbeddedEntryTargetRichTextVideo =
  RichTextEmbeddedEntryTargetEntry<
    'richTextVideo',
    {
      url: string
      title?: string
    }
  >

export function validateEmbeddedEntryNodeData(
  data: NodeData | null | undefined
): RichTextEmbeddedEntryData | null {
  if (!data?.target) {
    return null
  }

  const contentType = extractEmbeddedEntryTargetContentType(data.target)
  if (!contentType) {
    return null
  }

  switch (contentType) {
    case 'richTextCallToAction': {
      const target = validateEmbeddedEntryTargetRichTextCallToAction(
        data.target
      )
      return target && { contentType, target }
    }
    case 'richTextVideo': {
      const target = validateEmbeddedEntryTargetRichTextVideo(data.target)
      return target && { contentType, target }
    }
    default:
      return null
  }
}

function extractEmbeddedEntryTargetContentType(target: any): string | null {
  return target?.sys?.contentType?.sys?.id || null
}

function validateEmbeddedEntryTargetRichTextCallToAction(
  target: any & {
    sys: { contentType: { sys: { id: 'richTextCallToAction' } } }
  }
): RichTextEmbeddedEntryTargetRichTextCallToAction | null {
  const { title, address, variant } = target.fields || {}

  if (
    !validateStringField(title) ||
    !validateStringField(address) ||
    !validateStringField(variant)
  ) {
    return null
  }

  return { sys: target.sys, fields: { title, address, variant } }
}

export function isEmbeddedEntryTargetRichTextCallToAction(
  target: any
): target is RichTextEmbeddedEntryTargetRichTextCallToAction {
  return Boolean(
    extractEmbeddedEntryTargetContentType(target) === 'richTextCallToAction' &&
      validateEmbeddedEntryTargetRichTextCallToAction(target)
  )
}

function validateEmbeddedEntryTargetRichTextVideo(
  target: any & {
    sys: { contentType: { sys: { id: 'richTextVideo' } } }
  }
): RichTextEmbeddedEntryTargetRichTextVideo | null {
  const { url, title } = target.fields || {}

  if (!validateStringField(url)) {
    return null
  }

  return { sys: target.sys, fields: { url, title } }
}

function validateStringField(value: any): value is string {
  if (!value || typeof value !== 'string') {
    return false
  }

  return true
}
