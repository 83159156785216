import { HighlightAttributes } from '@scentregroup/shared-graphql'

export function isMemberExclusive(
  highlightAttribute: HighlightAttributes | null | undefined
): boolean {
  if (highlightAttribute === null || highlightAttribute === undefined) {
    return false
  }
  return highlightAttribute === HighlightAttributes.Memberexclusive
}
