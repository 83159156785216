import React from 'react'

import { RichTextEmbeddedEntryTargetRichTextVideo } from '../node-data'
import { getYoutubeUrlDetails } from '../../../../helpers/youtube-url'
import YoutubeLink from '../../../../components/youtube-link'

export function RichTextEmbeddedEntryBlockRichTextVideo({
  target: {
    fields: { url, title },
  },
}: {
  target: RichTextEmbeddedEntryTargetRichTextVideo
}): JSX.Element | null {
  if (!url) {
    return null
  }

  const youtubeDetails = getYoutubeUrlDetails(url)

  if (!youtubeDetails) {
    return null
  }

  return (
    <div className="mt-6 first:mt-0">
      <YoutubeLink youtubeId={youtubeDetails.youtubeId} title={title} />
    </div>
  )
}
